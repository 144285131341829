
// amsal,0 
// deathnote,1 
// evangelion,2 
// haikyu,3
// jujustsu_kaisen,4
// kaguya,5
// lovelive,6
// myhero,7
// spyfamily,8
// tokyo_g,9
// tokyo_re,10
// tonari,11

// 우선순위를 각 칸마다 임의로 부여
// 해당하는 항목에 대해 가장 큰거를 제일 큰값으로 부여함
// 점수범위: int: -10(0) ~ 10(0) (점수의 간격 크게 부여:정밀성을 위해)
// add_score에 있는 배열 다 score에 하나씩 

// 번호(index) : ~
// 점수 차등: 10 9 8 6 4 2 0 -2 -4 -6 -8 -10

// 질문에 해당되지 않는것들끼리의 점수차이: -1,-2~ 이런식..? -> 이렇게 크면안됨
// 범위를 크게하는 대신에 점수를 정밀하게 측정해야함... --> 점수 편향될 가능성 큼. -10~10까지해보고 점수범위 상황봐서 변경하기 ㅇㅇ 

export const QuestionData = [

  {
    q: 'Q1. 나는 애니메이션을 볼 때 ...',
    a: [
        { answer: 'a. 인물의 감정에 초점을 맞춰 본다.', add_score: [-4, 4, 10, 2, -6, -8, 9, 0, -10, 8, -2, 6] }, 
        //(2> 6> 9> 11> 1 > 3> 7> 10> 0> 4> 5> 8)// 12칸 array
        // 10 9  8   6  4   2  0  -2 -4 -6 -8 -10

        { answer: 'b. 사건 진행에 초점을 맞춰 본다.', add_score: [6, 9, -2, 8, 4, -10, -4, 2, -8, -6, 10, 0] },
        //(10> 1> 3> 0> 4> 7> 11> 2> 6> 9> 8> 5)
        // 10  9  8  6  4  2  0  -2 -4 -6 -8 -10

        { answer: 'c. 전개가 빠른 애니메이션을 선호한다.', add_score: [4, 2, -2, 8, 10, -10, -6, 9, -8, -4, 6, 0] },
        // (4> 7> 3> 10> 0> 1> 11> 2> 9> 6> 8> 5)
        // 10  9  8  6   4  2  0  -2 -4 -6 -8 -10

        { answer: 'd. 전개가 느려도 괜찮다.', add_score: [-2, 0, 4, -6, -10, 10, 8, -8, 9, 6, -4, 2] },
        //(5> 8> 6> 9> 2> 11> 1> 0> 10> 3 > 7> 4)
    ]   // 10 9  8  6  4  2   0  -2 -4  -6 -8 -10
  },
  {
    q: 'Q2. 평소 즐겨보는 애니메이션 장르는 \n무엇인가요?',
    a: [
      { answer: 'a. sf/판타지/어드벤쳐', add_score: [10, 2,10,-10,10,-4,-6,10,-2,4,0,-8] },
      //(2 =7 =4 =0 >9 >1 >10  >8 >5  >6 >11 >3)
      // 10 10 10  10  4  2   0  -2 -4  -6 -8 -10
      { answer: 'b. 멜로/로맨스/코미디', add_score: [2,-8,-10,0,-2,10,6,4,10,-6,-4,10] },
      //(8  =5 =11>6>  7> 0>  3>  4> 10> 9> 1> 2)
      // 10 10 10  6  4  2   0  -2 -4  -6 -8 -10

      { answer: 'c. 범죄/스릴러/미스터리', add_score: [0,10,6,-4,4,-6,-10,-2,2,10,10,-8 ] },
      //(1= 9=10 >2 >4 >8  >0  >7 >3  >5 >11 >6)
      //10 10 10  6  4  2   0  -2 -4  -6 -8 -10

      { answer: 'd. 학원/스포츠물/아이돌', add_score: [2,-10,-6,10,0,6,10,-2,-4,-8,4,8] },
      // (6=3> 11> 5> 10> 0> 4> 7> 8>  2>  9> 1)
      // 10 10 8   6   4  2  0 -2 -4  -6 -8 -10
    ]
  },
  {
    q: 'Q3. 어떤 스토리를 선호 하시나요? ',
    a: [
        { answer: 'a. 주인공이 점점 강해지는 스토리', add_score: [2,-4,-2,8,9,-10,6,10,-8,4,0,-6] },
        // (7> 4> 3> 6> 9> 0> 10> 2> 1> 11> 8>5)
        // 10  9  8  6  4  2  0  -2 -4 -6 -8 -10

        { answer: 'b. 나의 멘탈을 시험하는 스토리', add_score: [2,6,10,-2,8,-6,-10,0,-4,9,4,-8] },
        // (2> 9> 4> 1> 10> 0> 7> 3> 8> 5> 11> 6)
        // 10  9  8  6  4   2  0  -2 -4 -6 -8 -10

        { answer: 'c. 보기만 해도 힐링이 되는 스토리', add_score: [0,-4,-10,4,-6,8,10,2,6,-8,-2, 9] },
        // (6> 11> 5> 8> 3> 7> 0> 10> 1> 4> 9>2)
        //  10  9  8  6  4  2  0  -2 -4 -6 -8 -10


        { answer: 'd. 치밀한 복선 회수가 매력인 스토리', add_score: [8,10,6,0,4,-10,-4,2,-8,-6,9,-2] },
        // (1> 10> 0> 2> 4> 7> 3> 11> 6> 9> 8 >5)
        //  10  9  8  6  4  2  0  -2 -4 -6 -8 -10
    ]
  },
  {
    q: 'Q4. 어떤 그림체를 선호하시나요?',
    a: [
        { answer: 'a. 따뜻한 그림체', add_score: [2,0,-4,-6,-8,8,9,4,10,-10,-2,6] },
        // (8 >6 >5 >11>7 >0> 1> 10> 2> 3> 4  >9)
        //  10 9  8  6  4  2  0  -2 -4 -6 -8 -10

        { answer: 'b. 소년만화체', add_score: [8,0,-8,6,4,-4,-10,10,-2,2,9,-6] },
        //(7> 10> 0> 3> 4> 9> 1>  8> 5 >11 >2 >6)
        // 10 9   8  6  4  2  0  -2 -4 -6  -8 -10

        { answer: 'c. 고전 그림체', add_score: [4,9,10,4,4,4,4,4,4,4,4,8] },
        // (2>1>11 =0 =8 =5 =9 =4 =3 =7 =10 =4)
        // 10 9 8  4  4  4  4  4   4  4  4  4

        { answer: 'd. 선이 굵고 강한 그림체', add_score: [0,2,6,8,9,-6,-8,-2,-10,10,4,-4] },
        // (9> 4 >3 >2 >10>1> 0>  7> 11>5> 6> 8)
        //  10 9  8  6  4  2  0  -2 -4 -6 -8 -10
    ]
  },
  {
    q: 'Q5. 어떤 캐릭터를 선호하시나요?',
    a: [
        { answer: 'a. 바보같지만 동료를 누구보다 아끼는 열혈 캐릭터', add_score: [4,-10,-4,9,6,-8,-2,8,0,2,10,-6] },
        // (10> 3> 7> 4> 0> 9> 8> 6> 2> 11> 5 >1)
        //  10  9  8  6  4  2  0  -2 -4 -6 -8 -10

        { answer: 'b. 뛰어난 두뇌로 치밀한 두뇌싸움을 통해 문제를 해결하는 캐릭터', add_score: [1,1,1,1,1,1,1,1,1,1,1,1] },
        // (1> 5> 10> 0> 6> 11> 8> 7> 3> 9> 2> 4)
       //  10  9   8  6  4  2   0 -2 -4 -6 -8 -10

        { answer: 'c. 어딘가 사연이 있어 보이는 캐릭터', add_score: [8,4,10,-4,6,-6,-8,-10,2,9,0,-2] },
        // (2> 9> 0> 4> 1> 8> 10> 11> 3> 5> 6> 7)
        //  10 9  8  6  4  2   0  -2 -4 -6 -8 -10
        { answer: 'd. 바라만 봐도 귀여운 캐릭터', add_score: [2,-10,0,4,-4,6,10 ,-2,9,-6,-8,8] },
        // ( 6> 8> 11> 5> 3> 0>  2> 7>  4> 9> 10>1)
        //  10  9  8   6  4  2   0  -2 -4 -6 -8 -10
    ]
  },
  {
    q: 'Q6. 어떤 엔딩을 선호 하시나요?',
    a: [
        { answer: 'a. 모두가 행복한 해피엔딩', add_score: [-8,-4,-6,8,-2,6,10,0,2,-10,4,9]},
        // (6> 11> 3>  5> 10> 8> 7>  4> 1> 2> 0  >9)
        //  10  9  8   6  4   2  0  -2 -4 -6 -8 -10
        { answer: 'b. 눈물 나는 새드엔딩', add_score: [9,6,8,-6,4 ,-4,-10,2, 0,10,-2,-8] },
        // (9> 0> 2> 1> 4> 7> 8> 10> 5> 3> 11> 6)
        // 10  9  8  6  4  2  0  -2 -4 -6 -8 -10

        { answer: 'c. 불행한 결말을 맞는 베드엔딩', add_score: [6,10,9,-4,4,-6,-10,2,-2,8,0,-8] },
        // (1> 2> 9> 0> 4> 7> 10> 8> 3> 5> 11> 6)
        // 10  9  8  6  4  2  0  -2 -4 -6 -8 -10

        { answer: 'd. 독자의 상상에 맡기는 열린결말', add_score: [6,8,10,4,0,-6,-10,-8,-2,9,-4,2]},
        //(2> 9> 1> 0> 3> 11> 4> 8> 10> 5> 7> 6)
        // 10 9  8  6  4  2   0  -2 -4 -6 -8 -10

    ]
  },
  
]